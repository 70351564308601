<template>
<div id="MoveBackground">
  <div id="module"></div>
</div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  name: 'MoveBackground',
  setup () {

    let x_ratio = (1530 - 500) / 500;
    let y_ratio = (2300 - 500) / 500;

    onMounted(() => {

      const module = document.querySelector('#module');
      if (module) {
        module.onmousemove = event => {
          let offsetX = event.offsetX * x_ratio;
          let offsetY = event.offsetY * y_ratio;
          module.style.backgroundPosition = `${-offsetX}px ${-offsetY}px`;
        };
      }

    });

  },
};
</script>

<style lang="stylus" scoped>
#MoveBackground
  position absolute
  overflow hidden
  z-index 100
  top 70px
  right 70px
  bottom 70px
  left 70px
  border-radius 20px
  display flex
  align-items center
  justify-content center
  box-shadow 0 10px 30px 0 rgba(37, 44, 97, 0.15), 0 4px 18px 0 rgba(93, 100, 148, 0.2)
  background-image linear-gradient(#fff, #f5f5fa)
  #module
    background-image url('../../public/clip-path/_matterhorn.webp')
    background-size auto
    background-position center center
    background-repeat no-repeat
    border-radius 12px
    width 500px
    height 500px
</style>